<template>
  <div class="contact">
    <div id="content">
      <h1>Contact</h1>
      <p>For any inquiries, please contact me at <a href="mailto:photos@photosbyeternal.net" />photos@photosbyeternal.net</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}

</script>
<style></style>

